import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import _ from 'lodash'
import request from '@/services/axios_instance'
import CustomPhoneInput from '@/common/CustomPhoneInput.vue'

export default {
    name: 'ClientsDialog',
    components: {
        CustomDialog,
        CustomPhoneInput
    },
    props: {
        value: { type: Boolean, default: false },
        item: { type: Object },
        clients: Array
    },
    data: () => ({
        hardReload: 1,
        valid: false,
        open: false,
        show_password: false,
        show_repeat_password: false,
        first_name: null,
        last_name: null,
        company_name: null,
        telephone: null,
        contact_number: {
            input: null,
            number: null,
            valid: false,
            dialCode: null,
            countryCode: null
        },
        attachment: null,
        company_logo: null,
        contact_name: null,
        email: null,
        status: 'Active',
        location: null,
        password: null,
        repeat_password: null,
        status_items: [{
                id: 1,
                text: 'Active',
                value: 'Active'
            },
            {
                id: 2,
                text: 'Inactive',
                value: 'Inactive'
            }
        ],
        telephone_is_valid: false,
        defaultCountryCode: 'CA',
        is_new_client: true,
        is_new_client_items: [
            { text: 'Add as new client', value: true },
            { text: 'Attach to existing client', value: false }
        ],
        clients_list: [],
        existing_client: null
    }),

    watch: {
        clients: {
            handler: function(val) {
                this.clients_list = _.cloneDeep(val) 
            },
            immediate: true,
            deep: true
        },
        value: {
            handler: function(new_val) {
                this.open = new_val
                if (new_val && !this.isEditDialog) {
                    this.clearAll()
                }
                this.resetValidation()
            },
            immediate: true
        },
        open(new_val) {
            this.$emit('input', new_val)
        },
        item: {
            handler(new_val) {
                new_val && this.update_fields(new_val)
            },
            deep: true,
            immediate: true
        },
        is_new_client(val) {
            if (val) this.existing_client = null
        }
    },
    computed: {
        dialogTitle() {
            return this.isEditDialog ? 'Update Client' : 'New Client'
        },
        isEditDialog() {
            return this.item ? true : false
        },
        readyForSubmit() {
            return this.validation_passed() && this.valid
        },
        business_name_label() {
            return !this.is_new_client ? 'Business Name *' : 'Business Name'
        }
    },
    methods: {
        handleUpload(file) {
            if (file) {
                if (!file.type.includes('image/')) {
                    this.appSnackbar('File is not a supported image type', 'error')
                    return
                }
                if (file.size > this.settings.fileSizeMaxUploadLimit) {
                    this.appSnackbar(
                        `Image file size max limit is ${this.settings.fileSizeMaxUploadInMB}MB`,
                        'error'
                    )
                    return
                }
                var formData = new FormData()
                formData.append('file', file)
                request
                    .post(`api2/attachments/dropzone`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(({ data }) => {
                        this.attachment = data
                    })
            }
        },
        cancel() {
            this.open = false 
            this.$emit('cancelled', true)
        },
        validation_passed() {
            if (!this.is_new_client) {
                return !!(this.existing_client && this.company_name)
            }
            let tel_is_valid = true
            if (this.contact_number && this.contact_number.input) {
                tel_is_valid = this.contact_number.valid
            }
            if (!this.isEditDialog) {
                return !!(
                    tel_is_valid &&
                    this.password &&
                    this.repeat_password &&
                    this.first_name &&
                    this.last_name &&
                    this.email &&
                    this.status
                )
            }
            return !!(
                tel_is_valid &&
                this.first_name &&
                this.last_name &&
                this.email &&
                this.status
            )
        },
        save() {
            if (!this.is_new_client) {
                this.$emit('save', {
                    company_name: this.company_name,
                    client_id: this.existing_client.hasOwnProperty('id') ?
                        this.existing_client.id :
                        this.existing_client,
                    attachment: this.attachment
                })
                return
            }
            const fields_to_save = {
                first_name: this.first_name,
                last_name: this.last_name,
                company_name: this.company_name,
                telephone: this.contact_number,
                contact_name: this.contact_name,
                email: this.email,
                status: this.status,
                location: this.location,
                attachment: this.attachment
            }

            if (!this.isEditDialog) {
                if (this.password !== this.repeat_password) {
                    this.appSnackbar(`Passwords don't match`, 'error')
                    this.$event.$emit('btnloading_off', false)
                    return
                }
                fields_to_save.password = this.password
                fields_to_save.password_confirmation = this.repeat_password
            }
            this.$emit(this.isEditDialog ? 'update' : 'save', fields_to_save)
        },

        update_fields(fields) {
            const new_fields = _.cloneDeep(fields)
            this.first_name = new_fields.first_name
            this.last_name = new_fields.last_name
            this.company_name =
                (new_fields.company && new_fields.company.name) || null
            this.email = new_fields.email
            this.status = new_fields.props.status || 'Active'
            this.location = new_fields.props.location || null

            if (new_fields.telephone) {
                this.contact_number = {
                    input: new_fields.telephone.input ||
                        new_fields.telephone.phoneNumber ||
                        null,
                    number: new_fields.telephone.number || new_fields.telephone.e164 || null,
                    valid: new_fields.telephone.valid || new_fields.telephone.isValid || false,
                    dialCode: new_fields.telephone.dialCode ||
                        new_fields.telephone.countryCallingCode ||
                        null,
                    countryCode: new_fields.telephone.countryCode || null
                }
            } else {
                this.contact_number = {
                    input: null,
                    number: null,
                    valid: false,
                    dialCode: null,
                    countryCode: null
                }
            }
            this.is_new_client = true
        },
        clearAll() {
            this.first_name = ''
            this.last_name = ''
            this.company_name = ''
            this.location = ''
            this.contact_name = ''
            this.telephone = null
            this.email = ''
            this.status = 'Active'
            this.password = ''
            this.repeat_password = ''
            this.telephone_is_valid = false
            this.existing_client = null
            this.is_new_client = true
            this.attachment = null
        },
        clear_and_close() {
            this.clearAll()
            this.cancel() //close the modal
        }
    }
}