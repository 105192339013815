import { fetch_services } from '@/services/fetch/fetch_services'
import request from '@/services/axios_instance'
//Components
import Breadcrumb from '@/common/Breadcrumb.vue'
import VueTable from '@/common/VueTable/VueTable.vue'
import ClientsDialog from './components/ClientsDialog/ClientsDialog.vue'
import DeleteDialog from '@/common/DeleteDialog.vue'
import Actions from '@/common/VueTable/Actions.vue'
import TableHeader from '@/common/TableHeader.vue'
import VueGrid from '@/common/VueGrid/VueGrid.vue'
import MapperDialog from './components/MapperDialog/MapperDialog.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Clients',
  mixins: [fetch_services],
  components: {
    Breadcrumb,
    TableHeader,
    VueTable,
    VueGrid,
    ClientsDialog,
    DeleteDialog,
    Actions,
    MapperDialog
  },

  mounted() {
    this.$event.$emit('path-change', this.paths)
  },
  data: () => ({
    paths: [
      {
        text: 'Dashboard',
        disabled: false,
        route: { name: 'default-content' }
      },
      { text: 'Clients', disabled: true, router_name: null }
    ],
    headers: [
      { text: 'Client', align: 'left', value: 'fullname' },
      { text: 'Email', align: 'left', value: 'email' },
      { text: 'Contact No.', sortable: false },
      { text: 'Projects', sortable: false, align: 'center' },
      { text: 'Status', value: 'status' },
      {
        text: 'Action',
        value: 'action',
        sortable: false,
        align: 'center',
        width: '140px'
      }
    ],
    table_config: {
      route_name: 'clients',
      add_message: 'New Client added successfully!',
      update_message: 'Client updated successfully!',
      delete_message: 'Client deleted successfully!',
      refresh_table_message: 'Table refreshed',
      refresh_table_api_name: 'paginate_clients_table'
    },
    fields_map: [
      {
        label: 'Select field for email (Required, Field type: email)',
        field: 'email',
        required: true,
        match_id: null,
        match_value: null,
        field_type: 'email',
        icon: 'mdi-account-box-outline'
      },
      {
        label: 'Select field for first name (Required, Field type: any *)',
        field: 'first_name',
        required: true,
        match_id: null,
        match_value: null,
        field_type: '*',
        icon: 'mdi-account-details-outline'
      },
      {
        label: 'Select field for last name',
        field: 'last_name',
        required: false,
        match_id: null,
        match_value: null,
        field_type: '*',
        icon: 'mdi-account-details-outline'
      },
      {
        label: 'Select field for location',
        field: 'location',
        required: false,
        match_id: null,
        match_value: null,
        field_type: '*',
        icon: 'mdi-home-map-marker'
      },
      {
        label: 'Select field for business name',
        field: 'business_name',
        required: false,
        match_id: null,
        match_value: null,
        field_type: '*',
        icon: 'mdi-office-building'
      }
    ],
    mapper_dialog: false,
    mapping: [],
    activeMapping: null,
    delete_mapping_dialog: false,
    isEditing: false,
    clientStatuses: ['All', 'Active', 'Inactive'],
    activeStatus: 'All',
    clientList: []
  }),

  filters: {
    phoneDisplayForm: function (value) {
      return value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 $3')
    }
  },

  created() {
    this.loadClients()
    this.getMapForms()
    this.getClientList()
  },
  computed: {
    ...mapGetters(['view_type'])
  },
  methods: {
    getClientList() {
      request.get(`api/clients?all=true&list_only=true`).then(({ data }) => {
        this.clientList = data
      })
    },
    navigate_to_view_profile(username) {
      this.$router.push({
        name: 'profile',
        params: { username: username }
      })
    },
    filterByStatus(status) {
      this.activeStatus = status
      this.loadClients()
    },
    loadClients() {
      this.loadItems(
        `api/clients?status=${this.activeStatus}`,
        true,
        null,
        null
      )
    },
    getMapForms() {
      request.get(`api/clients/form/map`).then(({ data }) => {
        this.mapping = data
      })
    },
    addMapping() {
      this.isEditing = false
      this.mapper_dialog = true
    },
    handleNewMapping(payload) {
      request
        .post(`api/clients/form/map`, payload)
        .then(({ data }) => {
          this.mapping.push(data)
          this.appSnackbar('Form mapping successful.')
        })
        .finally(() => {
          this.mapper_dialog = false
          this.isEditing = false
        })
    },
    deleteMapping(map) {
      this.activeMapping = map
      this.delete_mapping_dialog = true
    },
    handleDeleteMapping() {
      request
        .delete(`api/clients/form/map/${this.activeMapping.id}`)
        .then(({ data }) => {
          let index = this.mapping.findIndex(
            (i) => i.id === this.activeMapping.id
          )
          if (~index) {
            this.mapping.splice(index, 1)
            this.appSnackbar('Form mapping successful removed.')
          }
          this.activeMapping = null
        })
        .finally(() => (this.delete_mapping_dialog = false))
    },
    editMapping(map) {
      this.isEditing = true
      this.activeMapping = map
      this.mapper_dialog = true
    },
    handleUpdateMapping(payload) {
      request
        .put(`api/clients/form/map/${this.activeMapping.id}`, payload)
        .then(({ data }) => {
          let index = this.mapping.findIndex((i) => i.id === data.id)
          if (~index) {
            this.mapping.splice(index, 1, data)
            this.appSnackbar('Form mapping successful updated.')
          }
        })
        .finally(() => {
          this.mapper_dialog = false
          this.isEditing = false
        })
    },
    handleSaveClient(payload) {
      this.addItem(
        `api/clients`,
        payload,
        'Client created',
        () => {
          this.loadClients()
          this.getClientList()
        },
        () => {
          this.action_add_edit_dialog(false, null)
        }
      )
    },
    handleUpdateClient(payload) {
      this.updateItem(
        `api/clients/${this.activeItem.id}`,
        payload,
        'Client info updated',
        () => {
          this.loadClients()
        },
        () => {
          this.action_add_edit_dialog(false, null)
        }
      )
    },
    handleDeleteClient() {
      this.deleteItem(
        `api/clients/${this.activeItem.id}`,
        'Client deleted',
        () => {
          this.action_delete_dialog(false)
        },
        null
      )
    }
  }
}
