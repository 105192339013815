<template>
  <CustomDialog
    :title="dialogTitle"
    ref="dialog"
    :open.sync="open"
    :button2-text="isEdit ? 'Update' : 'Save'"
    @button1="cancel"
    @button2="save"
    :max-width="800"
    :main-btn-disabled="!readyForSubmit"
  >
    <template slot="content">
      <v-card flat>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" class="mb-2" v-show="!isEdit">
              <label class="primary--text subtitle-2">Select form to map</label>
              <v-select
                outlined
                dense
                v-model="selected"
                :items="forms"
                item-text="title"
                :disabled="isEdit"
                item-value="id"
                hide-details="auto"
                return-object
              >
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.title | ucwords }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-select>
              <h5
                class="mx-auto my-5 primary--text text-center"
                v-if="selected"
              >
                Map the fields below from the selected form fields
              </h5>
              <empty v-else headline="Select a form to map" />
            </v-col>
            <v-col cols="12" class="mb-1 py-3">
              <template v-for="(field, index) in maps">
                <v-row
                  dense
                  v-if="selected"
                  justify="space-between"
                  align="center"
                  :key="index"
                >
                  <v-col cols="3" class="mb-1 d-flex">
                    <v-chip
                      color="primary"
                      class="border-0 fullwidth py-5 light--text subtitle-2"
                      label
                    >
                      <v-icon color="primary" left>{{ field.icon }}</v-icon>
                      {{ field.field | normalize | ucwords }} :
                    </v-chip>
                  </v-col>
                  <v-col cols="9" class="mb-1">
                    <v-select
                      v-model="field.match_id"
                      :items="getMatchField(field)"
                      item-text="label"
                      item-value="id"
                      :placeholder="field.label"
                      clearable
                      outlined
                      dense
                      hide-details="auto"
                      clear-icon="mdi-close-circle-outline"
                    >
                      <template v-slot:item="data">
                        <template>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ data.item.label }}
                            </v-list-item-title>
                            <v-list-item-subtitle class="caption">
                              Field Type :
                              {{ data.item.type | normalize | ucwords }},
                              Required: {{ data.item.required ? 'Yes' : 'No' }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
    <template v-slot:left-buttons>
      <v-btn
        depressed
        class="text-none"
        color="primary"
        @click="navigateCreateForm"
      >
        Create New Form
      </v-btn>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import request from '@/services/axios_instance'
import { mapGetters } from 'vuex'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'MapperDialog',
  components: {
    CustomDialog
  },
  props: {
    value: { type: Boolean, default: false },
    fields: Array,
    exceptForm: Array,
    item: Object,
    isEdit: { type: Boolean, default: false }
  },
  data: () => ({
    open: false,
    loading: false,
    selected: null,
    forms: [],
    maps: [],
    original: []
  }),
  computed: {
    ...mapGetters(['user']),
    dialogTitle() {
      return this.isEdit ? 'Update Form Mapping' : 'Add Form Mapping'
    },
    readyForSubmit() {
      if (!this.maps) return false
      for (let i = 0; i < this.maps.length; i++) {
        if (this.maps[i].required && !this.maps[i].match_id) {
          return false
        }
      }
      return true
    },
    existingForm() {
      return this.exceptForm.map((item) => {
        return item.form.id
      })
    },
    sections() {
      return this.selected && this.selected.questions
        ? this.selected.questions.filter((i) => i.tag === 'FormSection')
        : []
    }
  },
  watch: {
    selected: {
      handler(val) {
        if (!this.isEdit) this.maps = _cloneDeep(this.original)
      },
      immediate: true,
      deep: true
    },
    value(new_val) {
      this.open = new_val
      if (!this.isEdit) this.clear()
      if (new_val) this.getForms()
    },
    open(new_val) {
      this.$emit('input', new_val)
    },
    fields: {
      handler(val) {
        this.maps = _cloneDeep(val)
        this.original = _cloneDeep(val)
      },
      deep: true,
      immediate: true
    },
    item: {
      handler(val) {
        if (val) {
          this.selected = val.form
          this.maps = _cloneDeep(val.data)
        }
      },
      deep: true,
      immediate: true
    }
  },
  filters: {
    normalize(value) {
      return value.replace('_', ' ')
    }
  },
  methods: {
    cancel() {
      this.open = false
      this.clear()
    },
    getMatchField(field) {
      if (this.sections.length) {
        let values = this.sections.map((i) => i.value)
        values = [].concat.apply([], values)
        return values.filter((x) => {
          if (field.required && !x.required) return false
          return x.hasOwnProperty('value')
        })
      }
      return []
    },
    getForms() {
      this.loading = true
      request
        .get(`api/forms?all=true`)
        .then(({ data }) => {
          this.forms = data.filter((item) => {
            return !this.existingForm.includes(item.id)
          })
        })
        .finally(() => (this.loading = false))
    },
    clear() {
      this.selected = null
      this.maps = this.original
    },
    save() {
      if (!this.readyForSubmit) {
        this.appSnackbar('Please fill in required fields.', 'error')
        return
      }
      this.$emit(this.isEdit ? 'update' : 'save', {
        map: this.maps,
        form_id: this.selected.id
      })
    },
    navigateCreateForm() {
      this.$router.push({
        name: 'form-builder',
        query: { redirect_uri: this.$route.fullPath }
      })
    }
  }
}
</script>

<style scoped></style>
